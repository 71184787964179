
import { useEffect, useState } from 'react';

function randomize(length, chars) {
  let s = '';
  while(s.length < length){
    s += chars[Math.floor(Math.random()*chars.length)];
    console.log(s);
  }

  return s;
}

function Row({data}) {
  const urls = ['/404', '/kmart'];

  if(Math.random() > 0.5){
    const padding = 2;
    let splitPoint = padding+Math.floor(Math.random()*(data.length-padding));
    let a = data.substring(0, splitPoint);
    let b = data.substring(splitPoint+1);

    let randomUrl = urls[Math.floor(Math.random()*urls.length)];

    return (
      <div class="content">
        <h1><span>{a}</span><a href={randomUrl}>{data[splitPoint]}</a><span>{b}</span></h1>
      </div>
      );
  }
  else return (
    <div class="content">
      <h1><span>{data}</span></h1>
    </div>
    );
}

function App() {
  const chars = 'ᚠᚡᚢᚣᚤᚥᚦᚧᚨᚩᚪᚫᚬᚭᚮᚯᚰᚱᚲᚳᚴᚵᚶᚷᚸᚹᚺᚻᚼᚽᚾᚿᛀᛁᛂᛃᛄᛅᛆᛇᛈᛉᛊᛋᛌᛍᛎᛏᛐᛑᛒᛓᛔᛕᛖᛗᛘᛙᛚᛛᛜᛝᛞᛟᛠᛡᛢᛣᛤᛥᛦᛧᛨᛩᛪ᛫᛬᛭ᛮᛯᛰᛱᛲᛳᛴᛵᛶᛷᛸ';
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let strings = [];
    for(let i=0; i<15; i++){
      strings.push(randomize(50, chars));
    }
    setRows(strings);
  }, []);

  return (
    <div class="container">
      <section class='section is-large'>
        <div class="columns">
          <div class="column is-four-fifths">
            {rows.map((x, i) => <Row key={i} data={x}></Row>)}
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
